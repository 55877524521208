.dashboard {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 300px 1fr 1fr;
    grid-template-rows: 70px 1fr 0px;
    grid-template-areas: 
        "header header header"
        "menu main main"
        "menu footer footer";
    background: #003478;
    transition: all .3s;
    caret-color: transparent;
}

.menu {
    grid-area: menu;
}

.header {
    grid-area: header;
}

.main {
    grid-area: main;
    background:  radial-gradient(50% 50% at 50% 50%, rgb(13, 25, 44) 0%, rgb(0, 0, 0) 100%);
}

.footer {
    grid-area: footer;
    background: #003478;
}

.menu {
    transition: all .3s;
    left: 0px;
    z-index: 1000;
}

.menu.active {
    left: 0px!important;
}

@media screen and (max-width: 1024px) { 
    .dashboard {
        grid-template-areas: 
            "header header header"
            "main main main"
            "footer footer footer";
        transition: all .3s;
    }

    .menu {
        left: -300px;
    }  

    .menu.active {
        left: 0px!important;
    }
}

.menu >.ul-nav {
    height: 100%;
}

.menu.active >.ul-nav {
    width: 285px!important;
}

.icon-split {
    transition: all .3s;
}

.fondo-negro {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: #000;
    opacity: 0.3;
}

.ul-nav {
    width: 285px;
    padding-bottom: 60px;
    background-color: #fff;
    z-index: 999;
    overflow-y: auto;
    will-change: transform;
    backface-visibility: hidden;
    padding: 0;
    /* box-shadow: 0px 10px 20px -4px #000; */
    transition: all .5s;
}


/*acciones li*/
.ul-nav-li {
    display: block;
    user-select: none;
    list-style: none;
    /* line-height: 48px; */
    font-size: .9rem;
}

.li-nav-div {
    padding: 0 0rem;
    line-height: inherit;
    cursor: pointer;
    transition: all .3s;
    height: 36px;
}

    /* .li-nav-div:hover {
        background: #DBDBDB;
        opacity: 1;
    } */

    .li-nav-div i {
        width: 1rem;
        font-size: 1rem;
        display: inline-block;
        text-align: center;
        margin-right: .3rem;
    }
/*acciones li*/

.li-nav-div{
    background: #003478;
    /* margin-bottom: 5px; */
    padding: 0 8px!important;
    /* border-top:#000000 0.1rem solid; */
    border-top:#000000 0.1rem solid;

    /* border-bottom: #adb4d3 thick solid; */
    /* border-bottom:#c0bdbd 0.01rem solid ; */

}
.li-nav-div-submenu  {
    background: #02050A;
    /* margin-bottom: 5px; */
    padding: 0 8px!important;
}

/*Sub menus*/
.ul-submenu {
    padding: 0;
}

.ul-nav-li-submenu {
    display: block;
    list-style: none;
    /* line-height: 45px; */
    font-size: .9rem;
}

.li-nav-div-submenu {
    padding: 0 0rem;
    line-height: inherit;
    cursor: pointer;
    transition: all .3s;
    height: 32px;
    border-top: 1px solid #1d1e21
}

    /* .li-nav-div-submenu:hover {
        
        background :#bbdefb;
        opacity: 1;
    } */

    .li-nav-div-submenu i {
        width: 1rem;
        font-size: 1rem;
        display: inline-block;
        text-align: center;
        margin-right: .3rem;
    }
/*Sub menus*/

/*mostrar submenu*/
.active-li {
    background-color: #DBDBDB;
}

.ul-submenu {
    border: 0;
    background-color: #fff;
    display: block;
}

.active-submenu {
    background: #bbdefb;
}

.nav-link {
    padding: 0 0 0 10px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.nav-link.active {
    color: #cacdf5;
}

.icon-menu {
    transition: all 0.5s;
}

.nav-link.active > div > .icon-menu {
    transform: rotate(-180deg);
}

.li-a-submenu.active {
    background: #25567a;
    color: #9fc0da;
    /* border-radius: 4px; */
}

.nav-link:hover {
    /* background: #264258; */
    background: #383838;
    color: #fff;
    /* border-radius: 4px; */
}

.nav-link.active:hover {
    background: #264258;
    color: #2192eb;
    /* border-radius: 4px; */
}

.text-menu {
}

.text-sub-menu {
    padding-left: 20px;
}


/* TABLE CSS */
.table {
    width: 100%;
}

.table tr th {
    background: #1967af;
    color: white;
    padding-top: 5px !important; /*Era 10 px*/
    padding-bottom: 5px !important; /*Era 10 px*/
    /* border-bottom: 1px solid black !important; */
    font-size: 12px;
    /* width: 100%; */
}

.table thead tr {
    height: 40px;
}

.table tbody tr {
    height: 30px;
}

.table tbody tr:hover {
    background: #264258!important;
    cursor: pointer;
}

.table tbody tr td {
    font-size: 13px;
    height: 15px
    /* border-top: 1px solid #ddd; */
}

.table thead tr th:hover {
    cursor: pointer;
}

.table tbody tr:nth-child(odd) {
    background-color: #000000;
}

.table tbody tr:nth-child(even) {
    background-color: #505050;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
    padding: 8px;
    padding-top: 3px;
    /* line-height: 1.42857143; */
    vertical-align: middle;
    padding-bottom: 0px;
}

.table thead tr th { 
    position: sticky!important;
    top: 0!important;
    z-index: 10!important;
    /* border-bottom: 1px solid #000!important; */
}

.table thead tr:nth-child(2) th { 
    top: 30px!important;
}

.contenedor-tabla {
    height: calc(100% - 48px - 28px);
    overflow: auto;
}


/* Recargas */
.form-content > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
}


/* SWITCH DEL MENU MONITOREO/INCIDENTES - BOTON SEGUIMIENTO */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 22px;
    outline: none;
}
.switch input {
    position: absolute;
    top: -99999px;
    left: -99999px;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}
.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 5px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}
input:checked + .slider {
    background-color: #2196F3;
}
input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(32px);
}


/* RADIOBUTTONS DEL MENU MONITOREO/INCIDENTES - BOTON SEGUIMIENTO */
.formularioRadio {
}
.formularioRadio h2 {
    font-size: 16px;
    color: #dfeb39;
    margin-bottom: 1px;
    margin-left: 1px; }
.formularioRadio > div {
    padding: 5px 0;
    border-bottom: 0px solid #ccc; }
.formularioRadio .radio label,
.formularioRadio .checkbox label {
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    position: relative;
    padding: 5px 30px 5px 35px;
    font-size: 1em;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .formularioRadio .radio label:hover,
    .formularioRadio .checkbox label:hover {
    background: rgba(65, 112, 150, 0.1); }
    .formularioRadio .radio label:before,
    .formularioRadio .checkbox label:before {
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    position: absolute;
    left: 15px;
    border-radius: 50%;
    background: none;
    border: 3px solid #ffffff; }
.formularioRadio input[type="radio"] {
    display: none; }
    .formularioRadio input[type="radio"]:checked + label:before {
    display: none; }
    .formularioRadio input[type="radio"]:checked + label {
    padding: 5px 15px;
    background: #0774da;
    border-radius: 2px;
    color: #fff; }
.formularioRadio .checkbox label:before {
    border-radius: 3px; }
.formularioRadio .checkbox input[type="checkbox"] {
    display: none; }
    .formularioRadio .checkbox input[type="checkbox"]:checked + label:before {
    display: none; }
    .formularioRadio .checkbox input[type="checkbox"]:checked + label {
    background: #ffd900;
    color: #fff;
    padding: 5px 15px; }


/* ESTILOS INPUT */
.caret-input{
    cursor:caret;
    caret-color:white;
}

    /* FIN ESTILOS INPUT */

/* .select-filtro{
    color:white;
    height: 30px;
    border-radius: 0.25rem;
    padding-left: 1rem; (16px) 
    padding-right: 1rem; (16px)
    width: 100%;
    background-color: rgb(39 39 42);
    font-size: 0.75rem; (12px)
    line-height: 1rem; (16px) 
    
} */

/* ESTILO DEL SCROLLBAR  */
.containerScroll::-webkit-scrollbar {
    width: 10px;     /* Tamaño del scroll en vertical */
    height: 10px;    /* Tamaño del scroll en horizontal */
    /* display: none;  Ocultar scroll */
}

.containerScroll::-webkit-scrollbar-thumb {
    background: #4675bb;
    /* border-radius: 4px; */
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.containerScroll::-webkit-scrollbar-thumb:hover {
    background: #5376ac;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.containerScroll::-webkit-scrollbar-thumb:active {
    background-color: #3e639b;
}
.containerScroll::-webkit-scrollbar-track {
    background: #474545;
    /* border-radius: 4px; */
}

/* Cambiamos el fondo cuando esté en active o hover */
.containerScroll::-webkit-scrollbar-track:hover,
.containerScroll::-webkit-scrollbar-track:active {
    background: #474545;
}
 /* PARA DISPOSITIVOS MOBILES */
@media screen and (max-width: 639px) { 
    .containerScroll::-webkit-scrollbar {
        width: 1px;     /* Tamaño del scroll en vertical */
        height: 0px;    /* Tamaño del scroll en horizontal */
        /* display: none;  Ocultar scroll */
    }
    
    .containerScroll::-webkit-scrollbar-thumb {
        background: #2e5289;
        /* border-radius: 4px; */
    }
    
    /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
    .containerScroll::-webkit-scrollbar-thumb:hover {
        /* background: #ac5e53; */
        /* box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2); */
    }
    
    /* Cambiamos el fondo cuando esté en active */
    .containerScroll::-webkit-scrollbar-thumb:active {
        /* background-color: #3e639b; */
    }
    .containerScroll::-webkit-scrollbar-track {
        background: #474545;
        /* border-radius: 4px; */
    }
    
    /* Cambiamos el fondo cuando esté en active o hover */
    .containerScroll::-webkit-scrollbar-track:hover,
    .containerScroll::-webkit-scrollbar-track:active {
        background: #474545;
    }

}




/* FIN DEL ESTILO DEL SCROLLBAR */




/* ESTILO DEL SCROLLBAR Seguimiento  */
.containerScrollSeguimiento::-webkit-scrollbar {
    width: 2px;     /* Tamaño del scroll en vertical */
    height: 5px;    /* Tamaño del scroll en horizontal */
    /* display: none;  Ocultar scroll */
}

.containerScrollSeguimiento::-webkit-scrollbar-thumb {
    background: #7f8997;
    /* border-radius: 4px; */
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.containerScrollSeguimiento::-webkit-scrollbar-thumb:hover {
    background: #5376ac;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.containerScrollSeguimiento::-webkit-scrollbar-thumb:active {
    background-color: #3e639b;
}
.containerScrollSeguimiento::-webkit-scrollbar-track {
    background: #474545;
    /* border-radius: 4px; */
}

/* Cambiamos el fondo cuando esté en active o hover */
.containerScrollSeguimiento::-webkit-scrollbar-track:hover,
.containerScrollSeguimiento::-webkit-scrollbar-track:active {
    background: #474545;
}
 /* PARA DISPOSITIVOS MOBILES */
@media screen and (max-width: 639px) { 
    .containerScrollSeguimiento::-webkit-scrollbar {
        width: 1px;     /* Tamaño del scroll en vertical */
        height: 0px;    /* Tamaño del scroll en horizontal */
        /* display: none;  Ocultar scroll */
    }
    
    .containerScrollSeguimiento::-webkit-scrollbar-thumb {
        background: #2e5289;
        /* border-radius: 4px; */
    }
    
    /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
    .containerScrollSeguimiento::-webkit-scrollbar-thumb:hover {
        /* background: #ac5e53; */
        /* box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2); */
    }
    
    /* Cambiamos el fondo cuando esté en active */
    .containerScrollSeguimiento::-webkit-scrollbar-thumb:active {
        /* background-color: #3e639b; */
    }
    .containerScrollSeguimiento::-webkit-scrollbar-track {
        background: #474545;
        /* border-radius: 4px; */
    }
    
    /* Cambiamos el fondo cuando esté en active o hover */
    .containerScrollSeguimiento::-webkit-scrollbar-track:hover,
    .containerScrollSeguimiento::-webkit-scrollbar-track:active {
        background: #474545;
    }

}




/* FIN DEL ESTILO DEL SCROLLBAR */









/**INPUT FILE***/

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    border-radius: 10px;
    max-width: 70%;
    font-size: 1rem;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.25rem 0.5rem;
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
}

.iborrainputfile {
	font-size:16px; 
	font-weight:normal;
	font-family: 'Lato';
}

/* style 1 */

.inputfile-1 + label {
    color: #fff;
    background-color: #0774da;
}

.inputfile-1:focus + label,
.inputfile-1.has-focus + label,
.inputfile-1 + label:hover {
    background-color: #1e64a5;
}


/**********End File Inputs**********/

/* DIV MOIBLE */

.divMovible {
    position: absolute;
    z-index: 9;
}

.divMovibleCabecera {
    padding: 10px;
    cursor: move;
    z-index: 10;
}

.active-option:hover{
    background-color: #379EFF;
}

/* FIN DIV MOBIBLE */


/* -------------------------------FOOTER  */

.bar {
    background: #003478;
    /* border-radius: 41px; */
    /* box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.1); */
    /* width: 350px; */
    height:70px; 
    /* margin: 32px calc(50% - 161px); */
    padding: 14px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    bottom: 0;
    position: fixed;
    bottom: 0;
  }
  
  @media screen and (max-width: 700px) {
      .bar {
          width: 100%;
      }
  }
  
  .item__link {
    display: inline-block;
    color: var(--text-color);
    text-decoration: none;
    height: 100%;
    padding: 9px 10px;
    /* border-radius: 22px; */
    display: flex;
    align-items: center;
  }
  
  .item__text {
    margin-left: 6px;
    font-weight: 500;
    overflow: hidden;
    max-width: 0;
    opacity: 0;
    transition: max-width 0.25s linear, opacity 0.75s;
  }
  
  .item__link--active .item__text {
    opacity: 1;
    max-width: 90px;
  }
  .item__link--active:nth-child(1) {
    background-color: var(--home-bg);
    color: var(--home-text);
  }
  .item__link--active:nth-child(1) .item__icon {
    fill: var(--home-text);
  }
  .item__link--active:nth-child(2) {
    background-color: var(--inbox-bg);
    color: var(--inbox-text);
  }
  .item__link--active:nth-child(2) .item__icon {
    fill: var(--inbox-text);
  }
  .item__link--active:nth-child(3) {
    background-color: var(--search-bg);
    color: var(--search-text);
  }
  .item__link--active:nth-child(3) .item__icon {
    fill: var(--search-text);
  }
  .item__link--active:nth-child(4) {
    background-color: var(--saved-bg);
    color: var(--saved-text);
  }
  .item__link--active:nth-child(4) .item__icon {
    fill: var(--saved-text);
  }
  


/* ///////////////////MENU INFERIOR  */

.navigation {
	position: relative;
	/* width: 400px; */
	/* height: 60px; */
	background: #003478;
	display: flex;
	justify-content: center;
	align-items: center;
	/* border-radius: 10px; */
}

.navigation ul {
	/* width: 350px; */
	display: flex;
}


.navigation ul li {
	list-style: none;
	position: relative;
	width: 70px; 
	/* height: 60px; */
	z-index: 2;
}

.navigation ul li a {
	text-decoration: none;
	color:#555;
	
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.navigation ul li a .icon {
	position:relative;
	background: #fff;
	display:block;
	width: 55px;
	height: 55px;
	text-align: center;
	line-height: 65px;
	border-radius: 65px;
	color: #222327;
	font-size: 1.5em;
	transition: 0.5s;
	transition-delay: 0s;
}

.navigation ul li.active a .icon {
	background: var(--clr);
	color: #fff;
	transform: translatey(-27px);
	transition-delay: 0.25s;
}

.navigation ul li a .icon:before {
	content: '';
	position: absolute;
	top: 10px;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--clr);
	border-radius: 50%;
	filter: blur(5px);
	opacity:0;
	transition: .5s;
	transition-delay: 0.15s;
}

.navigation ul li.active a .icon:before {
	opacity: 0.5;
	transition-delay: 0.25s;
}

.indicator {
	position: absolute;
	top: -35px;
	width: 70px;
	height: 70px;
	background: #fff;
	border-radius: 50%;
	z-index: 1;
	transition: 0.5s;
}

.indicator::before {
	content: '';
	position: absolute;
	top: 5px;
	left: -27.5px;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	box-shadow: 15px 18px #fff
}

.indicator::after {
	content: '';
	position: absolute;
	top: 5px;
	right: -27.5px;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	box-shadow: -15px 18px #fff
}

.navigation ul li:nth-child(1).active ~ .indicator {
	transform: translatex(calc(70px * 0));
}

.navigation ul li:nth-child(2).active ~ .indicator {
	transform: translatex(calc(70px * 1));
}

.navigation ul li:nth-child(3).active ~ .indicator {
	transform: translatex(calc(70px * 2));
}

.navigation ul li:nth-child(4).active ~ .indicator {
	transform: translatex(calc(70px * 3));
}

.navigation ul li:nth-child(5).active ~ .indicator {
	transform: translatex(calc(70px * 4));
}
.datosIncompletos{
    --tw-bg-opacity: 1;
    background-color: rgb(20 83 45/ var(--tw-bg-opacity)) !important;
    /* color:#000 */
}
.datosIncompletos:hover{
    color: #fff;
}


















